// import store from '@/state/store'

export default [
  {
    path: '/:id',
    name: 'default',
    meta: {
      authRequired: false,
    },
    component: () => import('./views/jobs/detail'),
  },
  {
    path: '*',
    name: 'error',
    component: () => import('./views/error/404'),
  },
]
